import React from "react";
import { Routes, Route} from "react-router-dom";
import MainScene from "../Scenes/MainScene/MainScene";
import News from "../Scenes/News";
import Games from "../Scenes/Games";
import Investor from "../Scenes/Investor";
import Contact from "../Scenes/Contact";


const RootRouter = (props) =>{

    return(
        <div>
            <Routes>
                <Route path="/home" element={<MainScene/>}/>
                <Route path="/news" element={<News/>}/>
                <Route path="/games" element={<Games/>}/>
                <Route path="/investor" element={<Investor/>}/>
                <Route path="/contact" element={<Contact/>}/>        
            </Routes>
        </div>
    )
}

export default RootRouter;