import React from "react";
import styled from "styled-components";

const StyledAboutUs = styled.div`
max-width: 100%;
padding: 74px 0 68px 0;
background: #131239 0% 0% no-repeat padding-box;
border-bottom: 1px solid #707070;
border-top: 1px solid #707070;

.aboutus-container{
    max-width: 1170px;
    margin: auto;
    padding: 0 15px;
}
.aboutus-row{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
}
.aboutus-description{
    width: 75%;
}
.aboutus-title{
    color: #FFFFFF;
    opacity: 1;
    text-transform: capitalize;
    font-size: 55px;
    font-family: 'primelight', sans-serif; 
}
.aboutus-description p{
    color: #FFFFFF;
    opacity: 0.55;
    font-size: 15px;
    font-family: 'Robot-Light', sans-serif; 
}
`

const AboutUs = (props) =>{
    return(
        <StyledAboutUs>
            <div className={"aboutus-container"}>
                <div className={"aboutus-row"}>
                    <h3 className={"aboutus-title"}>o nas</h3>
                    <div className={"aboutus-description"}>
                        <p>Nasze studio zostało założone w 2016 roku. 
                            W swojej działalności koncentrujemy się na grach o wyjątkowej oprawie wizualnej połączonej z oryginalną narracją i filmowym stylem opowiadania. 
                            Cel, jaki przed sobą postawiliśmy to stworzenie studia klasy Indie Premium. Naszą najbardziej rozpoznawalną produkcją jest Apocalipsis, który wzbogaciliśmy wieloma dodatkami. 
                            Rozwijamy się także w tworzeniu gier VR. Od 10 sierpnia 2021 roku jesteśmy notowani na rynku NewConnect.
                        </p>
                    </div>
                </div>
            </div>
        </StyledAboutUs>
    )
}

export default AboutUs;