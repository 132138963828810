import React from "react";
import styled from "styled-components";

const StyledPremiery = styled.div`
`

const Premiery = (props) =>{

  
    
  

    return(
        <StyledPremiery>
            <div className={"premiery-container"}>
                <h4 className={"premiery-title"}>Nadchodzące premiery</h4>
                <div className={"premiery-row"}>
            </div>
            </div>
        </StyledPremiery>
    )
}

export default Premiery;