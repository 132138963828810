import React from "react";
import styled from "styled-components";

const StyledInvesor = styled.div`
`

const Investor = (props) =>{
    return(
        <StyledInvesor>

        </StyledInvesor>
    )
}

export default Investor;