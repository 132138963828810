const data = {
    planData1: [
        {
            id: 1,
            planNumber: "01",
            planText:"Tworzenie gier własnych oraz wykonywania produkcji na zlecenie"
        },
        {
            id: 2,
            planNumber: "02",
            planText: "Rozbudowa działu work for hire specjalizującego się w portowaniu gier do wirtualnej rzeczywistości oraz produkcji symulatorów"
        },
        {
            id: 3,
            planNumber: "03",
            planText:"Tworzenia gier na platformę Play Station VR, która obecnie charakteryzuje się niewielką konkurencją, przy dużej ilości posiadanych headsetów (zestawów umożliwiających rozgrywkę)"
        },
        {
            id: 4,
            planNumber: "04",
            planText: "Zwiększenie potencjału wydawniczego tytułów Indie oraz zleceń work for hire"
        },
        
    ],
    planData2: [
        {
            id: 5,
            planNumber: "05",
            planText:"Zwiększenie zaangażowania dotyczącego współpracy z dużymi podmiotami zewnętrznymi (tj.: PlayWay, Ultimate Games, Movie Games, Manager Games"
        },
        {
            id: 6,
            planNumber: "06",
            planText: "Rozbudowa działu wydawniczego celem samodzielnego wydawania tytułów VR" 
        },
        {
            id: 7,
            planNumber: "07",
            planText: "Budowanie backkatalogu opartego na bazie produkcji na zlecenie, oraz tworzenie mechanik gier w trybie kilku graczy"
        },
        {
            id: 8,
            planNumber: "08",
            planText: "Rozwój studia deweloperskiego, kontynuacja polityki opartej na pozyskiwaniu znanych IP i motywy, w celu budowy jakości premium w obszarze wydawanych produktów"
        }
    ]
};

export default data;


    