import React from "react";
import styled from "styled-components";

const StyledNews = styled.div`
`

const News = (props) =>{
    return(
        <StyledNews>

        </StyledNews>
    )
}

export default News;