import React from "react";
import styled from "styled-components";
import img from "../../assets/img/img.png"



const StyledFirstScreen = styled.div`
max-width: 100%;
padding: 155px 0 155px 0;
background: transparent linear-gradient(91deg, #020548 0%, #272226 100%) 0% 0% no-repeat padding-box;

.firstscreen-container{
    max-width: 1170px;
    margin: auto;
    padding: 0 15px;
}
.firstscreen-row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.firstscreen-text_row{
    display: flex;
    flex-direction: column;
    width: 45%;
}
.firstscreen-title{
    color: #EFFC00;
    opacity: 1;
    margin-bottom: 30px;
    font-size: 67px;
    font-family: 'primelight', sans-serif; 
}
.firstscreen-description{
    color: #FFFFFF;
    opacity: 0.80;
    font-size: 28px;
    margin-bottom: 70px;
    font-family: 'Robot-Light', sans-serif; 
}
.firstscreen-btn{
    background: #F0FC3C 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 7px;
    opacity: 1;
    width: 350px;
    padding: 20px 24px;
    letter-spacing: 0.85px;
    text-transform: uppercase;
    font-size: 18px;
    font-family: 'primeregular', sans-serif; 
    text-align: center;
}
.firstscreen-img{
    width: 55%;
}
`

const FirstScreen = (props) => {
    return(
        <StyledFirstScreen>
            <div className={"firstscreen-container"}>
                <div className={"firstscreen-row"}>
                    <div className={"firstscreen-text_row"}>
                        <h1 className={"firstscreen-title"}>Independent studio</h1>
                        <p className={"firstscreen-description"}>We create games which original art style</p>
                        <button className={"firstscreen-btn"}> zobach nasze realizacje</button>
                    </div>
                    <div className={"firstscreen-img"}>
                      <img className={"header-logo_img"} src={img}/>
                    </div>
                </div>
            </div>
        </StyledFirstScreen>
    )
}

export default FirstScreen;