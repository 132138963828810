import React from "react";
import styled from "styled-components";

const StyledGames = styled.div`
`

const Games = (props) =>{
    return(
        <StyledGames>

        </StyledGames>
    )
}

export default Games;