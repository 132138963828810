import React from "react";
import styled from "styled-components";
import Whatsapp from "../../assets/img/footerIcons/whatsapp.svg";
import Telegram from "../../assets/img/footerIcons/telegram.svg";
import Viber from "../../assets/img/footerIcons/viber.svg";


const StyledFooter = styled.div`
max-width: 100%;
padding: 25px 0 25px;
background: #131239 0% 0% no-repeat padding-box;
border-top: 1px solid #707070;

.footer-row{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}
.footer-row_text{
    display: flex;
    flex-direction: row;
}
.footer-email{
    font: normal normal normal 16px/35px Prime;
    letter-spacing: 0.56px;
    color: #F0FC3C;
    margin-left: 5px;
}
.footer-text{
    font: normal normal 300 16px/35px Roboto;
    letter-spacing: 0.56px;
    color: #FFFFFF;
}
.footer-row_icons{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}
.icon{
    width: 30px;
    cursor: pointer;
    color: white;
    transition: 400ms;
}

.icon:hover{
    transform: scale(1.1, 1.1);
    color: #F0FC3C;
}
.footer-row_icon{
    margin-right: 10px
}
`

const Footer = (props) =>{
    return(
        <StyledFooter>
            <div className={"footer-container"}>
                <div className={"footer-row"}>
                    <div className={"footer-row_text"}>
                        <p className={"footer-text"}>Zachęcamy do napisania do nas e-maila:</p>
                        <p className={"footer-email"}>studio@punch-punk.com</p>
                    </div>
                    <div className={"footer-row_icons"}>
                        <div className={"footer-row_icon"}>
                            <a href="tg://resolve?domain=nataliasposts" className={"link-icon"}>
                                <Telegram className={"icon"}/>
                            </a>
                        </div>
                        <div className={"footer-row_icon"}>
                            <a href="viber://chat?number=%2B375336447452" className={"link-icon"}>
                                <Viber className={"icon"}/>
                            </a>
                        </div>
                        <div className={"footer-row_icon"}>
                            <a href="https://wa.me/375336447452" className={"link-icon"}>
                                <Whatsapp className={"icon"}/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </StyledFooter>
    )
}

export default Footer;