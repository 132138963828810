import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../../assets/img/logo.png"


const StyledHeader = styled.header`
background: transparent linear-gradient(91deg, #020548 0%, #272226 100%) 0% 0% no-repeat padding-box;

.header-container{
    max-width: 1170px;
    margin: auto;
    padding: 0 15px;
}
.header-row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 -15px 0 -15px;
}
.header-logo{
    width: 50%;
}
.header-logo_img{
    width: 20%;
    padding: 25px;
}
.header-menu{
    width: 50%;
}
.header-menu_items{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.header-menu_item{
    list-style-type: none;
    margin-right: 15px;
    :last-child {
        margin-right: 0px;
      }
    
.header-menu_link{
    letter-spacing: 1.52px;
    color: #FFFFFF;
    opacity: 0.35;
    font-size: 14px;
    text-transform: uppercase;
    font-family: 'primeregular', sans-serif; 
    cursor: pointer;
    transition: 0.3s;
}
.header-menu_link:hover{
    color: #FFFFFF;
    opacity: 1;
}

`

const Header = (props) =>{

    return(
        <StyledHeader>
            <div className={"header-container"}>
                <div className={"header-row"}>
                    <div className={"header-logo"}>
                        <Link to="/home" className={"header-logo_link"} src={logo}>
                            <img className={"header-logo_img"} src={logo}/>
                        </Link>
                    </div>
                    <nav className={"header-menu"}>
                        <ul className={"header-menu_items"}>
                            <li className={"header-menu_item"}>
                                <Link to="/news" className={"header-menu_link"}>news</Link>
                            </li>
                            <li className={"header-menu_item"}>
                                <Link to="/games" className={"header-menu_link"}>games</Link>
                            </li>
                            <li className={"header-menu_item"}>
                                <Link to="/investor" className={"header-menu_link"}>investor relations</Link>
                            </li>
                            <li className={"header-menu_item"}>
                                <Link to="/contact" className={"header-menu_link"}>contact</Link>
                            </li>
                        </ul>
                    </nav>
                </div>

            </div>
        </StyledHeader>
    )
}

export default Header;