// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/src/assets/fonts/prime/primelight.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/src/assets/fonts/prime/primeregular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("/src/assets/fonts/robot/Roboto-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("/src/assets/fonts/robot/Roboto-Light.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "* {\n  margin: 0;\n  padding: 0;\n  border: 0;\n  list-style: none;\n  text-decoration: none;\n  box-sizing: border-box;\n}\n\nhtml, body {\n  max-width: 100%;\n  overflow-x: hidden;\n}\n\n@font-face {\n  font-family: \"primelight\";\n  src: local(\"primelight\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n@font-face {\n  font-family: \"primeregular\";\n  src: local(\"primeregular\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n@font-face {\n  font-family: \"Robot-Regular\";\n  src: local(\"Robot-Regular\") url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n@font-face {\n  font-family: \"Robot-Light\";\n  src: local(\"Robot-Light\") url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}", "",{"version":3,"sources":["webpack://./src/style/index.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,UAAA;EACA,SAAA;EACA,gBAAA;EACA,qBAAA;EACA,sBAAA;AACJ;;AAEA;EACI,eAAA;EACA,kBAAA;AACJ;;AAEA;EACI,yBAAA;EACA,iEAAA;AACJ;AAEA;EACI,2BAAA;EACA,mEAAA;AAAJ;AAGA;EACI,4BAAA;EACA,mEAAA;AADJ;AAIA;EACI,0BAAA;EACA,iEAAA;AAFJ","sourcesContent":["* {\r\n    margin: 0;\r\n    padding: 0;\r\n    border: 0;\r\n    list-style: none;\r\n    text-decoration: none;\r\n    box-sizing: border-box;\r\n}\r\n\r\nhtml, body {\r\n    max-width: 100%; \r\n    overflow-x: hidden; \r\n}\r\n\r\n@font-face {\r\n    font-family: 'primelight';\r\n    src: local('primelight'),\r\n         url(/src/assets/fonts/prime/primelight.ttf);\r\n}\r\n@font-face {\r\n    font-family: 'primeregular';\r\n    src: local('primeregular'),\r\n         url(/src/assets/fonts/prime/primeregular.ttf);\r\n}\r\n@font-face {\r\n    font-family:'Robot-Regular';\r\n    src: local('Robot-Regular')\r\n         url(/src/assets/fonts/robot/Roboto-Regular.ttf);\r\n}\r\n@font-face {\r\n    font-family:'Robot-Light';\r\n    src: local('Robot-Light')\r\n         url(/src/assets/fonts/robot/Roboto-Light.ttf);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
