import React from "react";
import styled from "styled-components";
import data from "../../api/data";

const StyledPlan = styled.div`
background: transparent linear-gradient(91deg, #020548 0%, #272226 100%) 0% 0% no-repeat padding-box;
max-width: 100%;
padding: 105px 0 105px 0;

.plan-container{
    max-width: 1170px;
    margin: auto;
    padding: 0 15px;
}
.plan-title{
    text-align: center;
    font-size: 50px;
    font-family: 'primelight', sans-serif; 
    color: #FFFFFF;
    opacity: 1;
    margin-bottom: 110px;
}
.plan-row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.plan-row_items{
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    margin-right: 50px;
    :last-child {
        margin-right: 0px;
      }
}
.plan-row_item{
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
}
.plan-row_number{
    background: #F0FC3C 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 7px;
    padding: 12px;
    margin-right: 20px;
    align-self: center; 
    font-family: 'primeregular', sans-serif; 
    font-size: 18px;
    letter-spacing: 0.85px;
    width: 50px;
    height: 50px;
    text-align: center;

}
.plan-row_description{
    font-size: 15px;
    font-family: 'Robot-Regular', sans-serif; 
    color: #FFFFFF;
    opacity: 0.75;
}
`

const Plan = (props) => {
    return(
        <StyledPlan>
            <div className={"plan-container"}>
                <h3 className={"plan-title"}>
                    Zarys planów strategicznych
                </h3>
                <div className={"plan-row"}>
                    <div className={"plan-row_items"}>
                        {data.planData1.map((item, index)=>{
                            return(
                            <div className={"plan-row_item"}>
                                <h4 className={"plan-row_number"}>{item.planNumber}</h4>
                                <p className={"plan-row_description"}>{item.planText}</p>
                            </div>
                            )
                        })}
                        </div>
                        <div className={"plan-row_items"}>
                            {data.planData2.map((item1, index)=>{
                                return(
                                <div className={"plan-row_item"}>
                                    <h4 className={"plan-row_number"}>{item1.planNumber}</h4>
                                    <p className={"plan-row_description"}>{item1.planText}</p>
                                </div>
                                )
                            })}
                        </div>
                </div>
            </div>
        </StyledPlan>
    )

}

export default Plan;