import React from "react";
import Header from "../Scenes/Header/Header";
import Footer from "../Scenes/Footer/Footer";
import styled from 'styled-components';
import MainScene from "../Scenes/MainScene/MainScene";

const StyledMainLayout = styled.div`
`;

const MainLayout = (props) =>{

    return(
        <StyledMainLayout className={"web"}>
              <Header/>
              <MainScene/>   
              <Footer/>
        </StyledMainLayout>
    )
}

export default MainLayout;