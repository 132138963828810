import React from "react";
import styled from "styled-components";

const StyledContact = styled.div`

`

const Contact = (props) => {
    return(
        <StyledContact>

        </StyledContact>
    )
}

export default Contact;