import React from "react";
import FirstScreen from "./FirstScreen";
import AboutUs from "./AboutUs";
import Plan from "./Plan";
import Premiery from "./Premiery";


const MainScene = (props) => {
    return(
        <div className={"mainscene"}>
            <FirstScreen/>
            <AboutUs/>
            <Plan/>
        </div>
    )
    
}

export default MainScene;